.library {
  flex-grow: 1;
  display: grid;
  grid-template-areas: "sidebar searchbar" "sidebar contents";
  grid-auto-rows: auto 1fr;
  grid-auto-columns: 1fr 4fr;
  grid-gap: 0.5rem;

  .searchbar {
    grid-area: searchbar;
  }

  .sidebar {
    grid-area: sidebar;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn {
      display: flex;
      align-items: center;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
  }

  .flex-column {
    min-height: 0;
  }

}

.library-entry {
  padding: 0.5rem 1rem;
  cursor: pointer;
  word-break: break-word;
  word-wrap: break-word;
  list-style-type: none;

  background-color: white;

  display: grid;
  grid-template-areas: "icon title" "icon preview";
  grid-auto-columns: auto 1fr auto;
  grid-column-gap: 1rem;
  align-items: center;

  user-select: none;

  &:not(:first-child) {
    border-top: 1px solid #ccc;
  }

  &:last-child {
    border-bottom: 1px solid #ccc;
  }

  & > svg {
    grid-area: icon;
    align-self: flex-start;
  }

  &:not(:hover).selected {
    background-color: $highlight-color-dark;
    color: white;
     .text-muted {
      color: #ccc !important;
    }
  }

  &:hover {
    background-color: $highlight-color;
  }

  &--dragging {
    border: 1px solid #ccc;
  }

  span.title {
    grid-area: title;
    font-weight: bold;
  }

  span.content-preview {
    grid-area: preview;
    margin-left: 1rem;
    font-style: italic;
  }

  .library-tools {
    visibility: hidden;
    margin: 0;
    position: absolute;
    right: 0.5rem;
  }
}


.library-contents {
  grid-area: contents;
  padding: 0;
  margin: 0;
  margin-bottom: 0.5rem;
  flex-grow: 1;
  border: 1px solid #CCC;

  .library-entry {
    position: relative;

    min-height: 4rem;

    &:hover .library-tools, &.selected .library-tools {
      visibility: visible;
    }
  }

}

.limit-container {
  position: relative;
  max-width: 200px;
  align-self: center;
}

.limit-label {
  position: absolute;
  top: calc(50% - 0.5rem);
  width: 100%;
  text-align: center;
  font-size: xx-large;

  &.limit-label-full {
    color: red;
  }

  &.limit-label-mid {
    color: #cece34;
  }

  &.limit-label-ok {
    color: green;
  }
}
