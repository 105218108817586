.bible-editor {
  display: flex;

  & > div {
    flex-basis: 50%;
    padding: 1rem;
    overflow: auto;
  }

  .editor-controls {
    display: flex;
    flex-direction: column;

    .reference-controls {
      flex-grow: 1;
    }
  }
}
