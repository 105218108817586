.settings-editor {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .tab-pane {
    height: 100%;
  }
}

#settingsForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .tab-content {
    flex-grow: 1;
  }
}

.outputs-editor {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 4fr);
  flex-grow: 1;

  .output-form {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

.canvas-form {
  margin-left: 0.5rem;
  display: flex;

  & > div {
    flex-grow: 1;
  }
}
