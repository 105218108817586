ul.steps {
  list-style-type: none;

  @include media-breakpoint-down(sm) {
    padding-left: 0.5rem;
  }

  li.step {

    display: grid;

    grid-template-areas: "index title" "index content";
    grid-auto-columns: auto 1fr;

    border: 2px solid #cccccc;
    border-radius: 1rem;

    margin: 2rem;

    @include media-breakpoint-down(sm) {
      margin: 0.5rem;
    }

    .step-index {
      grid-area: index;
      font-size: xx-large;
      padding: 0.125rem 2rem;

      @include media-breakpoint-down(sm) {
         padding: 0.125rem 1rem;
      }
    }

    .step-title {
      grid-area: title;
      font-size: x-large;
      font-weight: bold;

      background-color: #EEE;
      border-top-right-radius: 1rem;

      padding: 0.5rem 1rem;
    }

    .step-content {
      grid-area: content;
      padding: 1rem;
    }

    &.step-complete {
       border-color: green;

       .step-title {
         background-color: #DDFFDD;
         border-bottom-right-radius: 1rem;
       }

       .step-index {
         color: green;
       }
    }

  }

}
