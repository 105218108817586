.playlist {
  grid-area: playlist;
  min-height: 0;

  .card {
    min-height: 0;
    margin-bottom: 0.5rem;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    min-height: 0;
  }

  .library-contents {
    flex-basis: 0;
    min-height: 0;
    overflow-y: auto;
  }

  .library-tools {
    position: absolute;
    right: 0.5rem;
    & > .btn {
      padding: 0.25rem 0;
    }

    & > .btn-group {
      margin-bottom: 0;

      .btn {
        padding: 0 0.5rem 0 0;
      }
    }

    h6 {
      color: gray;
    }
  }

  .playlist-entry {
    grid-template-areas: "icon title";
    grid-column-gap: 0.5rem;
    padding: 0.5rem;

    user-select: none;

    svg {
      align-self: center;
    }
  }
}

.playlist-live {
  background-color: $highlight-color-dark;
  color: white;

  &:hover {
    color: black;
  }
}
