// Spacelab 4.1.3
// Bootswatch

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #999 !default;
$gray-600: #777 !default;
$gray-700: #495057 !default;
$gray-800: #333 !default;
$gray-900: #2d2d2d !default;
$black:    #000 !default;

$blue:    #446E9B !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #CD0200 !default;
$orange:  #fd7e14 !default;
$yellow:  #D47500 !default;
$green:   #3CB521 !default;
$teal:    #20c997 !default;
$cyan:    #3399F3 !default;

$primary:       $blue !default;
$secondary:     $gray-500 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-800 !default;

$yiq-contrasted-threshold: 200 !default;

// Body

$body-color:                $gray-600 !default;

// Links

$link-color:                $info !default;

// Fonts

$font-family-sans-serif:      "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$headings-color:              $gray-900 !default;

// Navbar

$navbar-dark-color:                 rgba($white,.75) !default;
$navbar-dark-hover-color:           $white !default;

$navbar-light-color:                rgba($black,.4) !default;
$navbar-light-hover-color:          $info !default;
$navbar-light-active-color:         $info !default;
