// Spacelab 4.1.3
// Bootswatch
@import "~bootstrap/scss/bootstrap.scss";

// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,400,700" !default;
@import url($web-font-path);

@mixin btn-shadow($color){
  @include gradient-y-three-colors(lighten($color, 15%), $color, 50%, darken($color, 4%));
  filter: none;
  border: 1px solid darken($color, 10%);
}

// Navbar ======================================================================

.navbar {

  .nav-link,
  .navbar-brand {
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
    transition: color ease-in-out .2s;
  }

  &.bg-primary {
    @include btn-shadow($primary);
  }

  &.bg-dark {
    @include btn-shadow($secondary);
  }

  &.bg-light {
    @include btn-shadow($light);

    .nav-link,
    .navbar-brand {
      text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1);
    }

    .navbar-brand {
      color: $navbar-light-color;

      &:hover {
        color: $info;
      }
    }
  }
}

// Buttons =====================================================================

.btn {
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);

  &-primary {
    @include btn-shadow($primary);

    &:not(.disabled):hover {
      @include btn-shadow(darken($primary, 4%));
    }
  }

  &-secondary {
    @include btn-shadow($secondary);
    color: $white;

    &:not(.disabled):hover {
      @include btn-shadow(darken($secondary, 4%));
    }
  }

  &-success {
    @include btn-shadow($success);

    &:not(.disabled):hover {
      @include btn-shadow(darken($success, 4%));
    }
  }

  &-info {
    @include btn-shadow($info);

    &:not(.disabled):hover {
      @include btn-shadow(darken($info, 4%));
    }
  }

  &-warning {
    @include btn-shadow($warning);

    &:not(.disabled):hover {
      @include btn-shadow(darken($warning, 4%));
    }
  }

  &-danger {
    @include btn-shadow($danger);

    &:not(.disabled):hover {
      @include btn-shadow(darken($danger, 4%));
    }
  }

  &-dark {
    @include btn-shadow($dark);

    &:not(.disabled):hover {
      @include btn-shadow(darken($dark, 4%));
    }
  }

  &-light {
    @include btn-shadow($light);
    color: $navbar-light-color;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1);
    transition: color ease-in-out .2s;

    &:not(.disabled):hover {
      @include btn-shadow(darken($light, 4%));
      color: $info;
    }
  }

  &-link {
    text-shadow: none;
  }

  &:active,
  &.active {
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
}

[class*="btn-outline-"] {
  text-shadow: none;
}

// Typography ==================================================================

// Tables ======================================================================

// Forms =======================================================================

// Navs ========================================================================

// Indicators ==================================================================

.badge {

  &-secondary {
    color: $white;
  }
}

// Progress bars ===============================================================

// Containers ==================================================================

.card,
.list-group-item {
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
}
