@import 'settings.scss';
@import 'account';
@import 'bible';
@import 'dropzone';
@import 'getStarted';
@import 'library.scss';
@import 'playlist.scss';
@import 'resource.scss';
@import 'theme.scss';
@import 'settings_editor';

html, body, #root, .App {
  height: 100%;
}

body {
  background-color: #DDD;
}

.window {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header" "content";

  & > .header {
    grid-area: header;

    .toolbar {
      display: inline-flex;
      .navbar-text {
        margin-left: 1rem;
      }
    }
  }
}

.main-window {
  background-color: white;
  grid-area: content;
  min-height: 0;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows: auto 1fr 2rem;
  grid-gap: 0.25rem 1rem;
  grid-template-areas:
    "sidebar preview-toolbar live-toolbar"
    "sidebar preview live"
    "sidebar footer footer";
  padding: 0.5rem 1rem 0 1rem;

  & > .sidebar {
    grid-area: sidebar;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .library, .playlist {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }
  }

  & > .resource-area {
    display: flex;
    flex-direction: column;
  }

  & > .preview {
    @extend .resource-area;
    grid-area: preview;
    min-height: 0;
  }

  & > .preview-toolbar {
    grid-area: preview-toolbar;
    margin: 0;
  }

  & > .live {
    @extend .resource-area;
    grid-area: live;
    min-height: 0;
  }

  & > .live-toolbar {
    grid-area: live-toolbar;
    margin: 0;
  }

  & > .footer {
    grid-area: footer;
    text-align: right;
  }
}

.modal-dialog.full-size {
  position: fixed;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 2.5%;
  max-width: unset;

  .modal-header, .modal-footer {
    flex-shrink: 0;
  }

  .modal-content {
    height: 100%;
  }

  .modal-body {
    min-height: 0;
    overflow-y: auto;
  }
}

.confirm-popup {
  position: fixed;
  max-width: 40%;
  background-color: white;
  border: 1px solid red;
  font-size: 1rem;
  padding: 1rem;
  z-index: 100;

  .btn{
    float: right;
    &:not(:last-child) {
      margin-right: 0.25rem;
    }
    &:not(:first-child) {
      margin-left: 0.25rem;
    }
  }
}

.btn.disabled, .btn:disabled {
  cursor: not-allowed;
}

.btn-group {
  margin-bottom: 0.5rem;

  .btn {
    white-space: nowrap;
  }

  &.full-width {
    display: flex;
    flex-direction: row;

    .btn {
      width: 100%;
    }
  }
}

.tab-content {
  border: 1px solid $border-color;
  border-top: none;
  padding: 0.5rem;
}

.form-group.linear {
  display: flex;
  margin-bottom: 0.5rem;

  label {
    margin-bottom: 0;
    margin-right: 0.75rem;
    margin-top: 0.5rem;
    flex-grow: 1;
  }
  .form-check {
    display: inline-block;
    flex-grow: 1;
  }
  .form-field {
    width: auto;
    flex-basis: 70%;
    flex-grow: 3;

    .form-control {
      display: inline-block;
    }
  }
  .form-group {
    flex-grow: 3;
    flex-basis: 70%;
    margin-bottom: 0.25rem;
  }
}

@keyframes blank-button-flash {
  0% {
    background-color: black;
    color: white;
  }

  50% {
    background-color: white;
    color: black;
  }
}

.btn-blanked {

  border-color: black;
  background-color: white;
  color: black;

  &:active, &.active {
    animation: blank-button-flash 1s step-end infinite;
  }

}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;

  &.wrap {
    flex-wrap: wrap;
  }

  &:not(.btn-group) > :not(:last-child) {
    margin-right: 0.25rem;
  }

  .btn-group {
    margin-bottom: 0;

    .btn {
      margin-right: 0;
    }
  }

  .btn {
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h4 {
    margin-bottom: 0;
    line-height: 1.5;
    flex-grow: 2;
  }

  .form {
    flex-grow: 2;
  }

  .form-group {
    margin-bottom: 0;
  }

  label {
    margin: 0;
    padding-top: 0.4rem;
    margin-right: 0.25rem;
  }
}

.input-group > label ~ .form-control {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.navbar-dark {
  color: white;

  a, .btn-link {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.modeless {
  width: 40%;
  height: 40;
  margin: auto;
  position: relative;
  border: 1px solid $highlight-color-dark;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
}

.btn-link {
  padding: 0;
}

.btn {
  svg.svg-inline--fa {
    margin: 0 0.5rem;
    flex-grow: 0;
  }
}

.page-card {
  margin: 0.5rem 5rem;
  padding: 2rem;
  display: block;

  @include media-breakpoint-down(sm) {
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .card {
    margin-bottom: 2rem;
    margin-top: 2rem;
    border: 1px solid darken($blue, 10%);

    h2 {
      padding: 0.5rem 1rem;
      @include btn-shadow($blue);
      color: white;
    }
  }

  & > h2 {
    margin-left: 1rem;

    @include media-breakpoint-down(sm) {
      margin-left: 0.5rem;
    }
  }

  p, pre, li {
    margin-left: 2rem;

    @include media-breakpoint-down(sm) {
      margin-left: 1rem;
    }
  }
}

.main-image {
  display: block;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.pricing-table {
  th, td {
    vertical-align: middle;
  }

  .price-summary td  {
    text-align: center;

    & > span {
      display: block;
      font-weight: bold;
      margin-bottom: 0.75rem;
    }
  }
}

select.theme-select {
  max-width: 20em;
}

.item-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  ul {
    padding: 0;
    margin: 0 0 0.5rem 0;
    flex-grow: 1;
    border: 1px solid #CCC;

    list-style-type: none;

    li {
      padding: 0.25rem;
      user-select: none;

      &:not(.live):hover {
        background-color: $highlight-color;
      }

      &.live {
        background-color: $highlight-color-dark;
        color: white;
      }
    }
  }
}
