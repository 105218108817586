.theme-editor {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;

  .theme-form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    min-height: 0px;

    form {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;

      .tab-content {
        min-height: 0;
        flex-grow: 1;
        overflow: hidden auto;
      }

      label {
        white-space: nowrap;

        &:not(:first-child) {
          margin-left: 0.25rem;
        }
      }
    }

    .font-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
    }
  }

  .colour-block {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    border: 1px solid black;
    cursor: pointer;
  }

  .sketch-picker {
    position: absolute;
    z-index: 100;
    top: 100%;
    left: 0;
    min-width: 250px;
  }

  .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
  }
}

.btn-group.library-tools .btn {
  flex-grow: 1;
}

.form-group.margins {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "left top right"
    "left bottom right";

  row-gap: 1rem;

    .top { grid-area: top; }
    .bottom { grid-area: bottom; }
    .left { grid-area: left; }
    .right { grid-area: right; }

    .left, .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .form-group {
      margin-bottom: 0;
    }

    label, .form-control {
      text-align: center;
      width: 100%;
    }
}
