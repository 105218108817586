$secondary: #888;

$modal_sm: 33%;
$modal_md: 50%;
$modal_lg: 75%;

$highlight-color: #CCE0FF;
$highlight-color-dark: #3e648d;
$highlight-color-light: lighten($highlight-color, 5);

$dropdown-link-hover-bg: $highlight-color;

$nav-tabs-link-active-bg: $highlight-color-dark;
$nav-tabs-link-active-color: white;

$body-color: black;

$font-family-sans-serif: Arial, Helvetica, sans-serif;

$navbar-padding-y: 0;

@import 'spacelab/variables';
@import 'spacelab/bootswatch';
