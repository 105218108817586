$type-descriptor-color: #AAA;

.resource {

  font-size: large;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem;
  height: 100%;
  overflow: auto;

  background-color: #F9F9F9;

  h4 {
    word-break: break-word;
    word-wrap: break-word;
    text-align: center;
  }

  .block {
    padding: 0.5rem;
    border-left: 2px solid transparent;

    &::before {
      content: attr(data-descriptor);
      color: $type-descriptor-color;
      font-style: normal;
    }
    &::first-letter {
      text-transform: capitalize;
    }

    &.chorus {
      font-style: italic;

      .lineGroup {
        margin-left: 2rem;
      }
    }

    &.bridge {
      font-style: italic;
    }

    &.live {
      border-left-color: $highlight-color-dark;
    }

  }

  .lineGroup {
    min-height: 1.5em;
    padding: 0 0.5rem;
    margin-left: 1rem;
    background-color: white;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.live {
      background-color: $highlight-color;
    }
  }

  .line {
    &.minor {
      color: #888;
      &::selection {
        background-color: #ccc;
      }
    }

    &.scripture {
      display: inline;
    }
  }

  &:not(.editable) {
    cursor: default;
    user-select: none;
    .lineGroup:not(.live):hover {
      background-color: $highlight-color-light;
    }
  }

  &.editable {
    .lineGroup {
      border: 1px dashed $highlight-color;
      &:not(:last-child) {
        border-bottom: none;
      }
    }
  }

}

#resourceForm {
  flex-grow: 1;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "fields controls"
    "fields editor"
    "meta editor";
  grid-gap: 0 1rem;

  .fields { grid-area: fields; }
  .editor-controls { grid-area: controls; }
  .meta {
    grid-area: meta;
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-content: start;
    grid-gap: 0.5rem 0.25rem;

    label {
      margin-top: 0.5rem;
    }
    .form-check {
      display: inline-block;
      margin-right: 1rem;
    }
  }
  .resource {
    position: relative;
    grid-area: editor;
    .resource-content {
      position: absolute;
      width: 90%;
    }
  }
}

.import-resource-dropzone {
  display: block;
}
