.dropzone {
  border: 2px dashed $highlight-color;
  padding: 1rem;

  flex-basis: 50%;

  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--isActive {
    background-color: $highlight-color;
    border-color: $highlight-color-dark;
  }
}
